import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import * as NetworkAdapter from '../../adapters/network_adapter';
import { clearClientData, setClientData } from '../../redux/actions/client_data_actions';

import { SuspenseFallback } from '../../components/molecules';

const BAD_REQUEST = { message: 'The request could not be completed due to malformed syntax. Kindly crosscheck and try again.' };
const UNKNOWN_ERROR = { code: '50000', message: 'An unexpected error has occured. Please try again later.' };

const ParamsResolver = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = queryString.parse(location.search);
        navigate('/', { replace: true });

        if (params.clientKey) {
            handleClientInit(params);
        } else if (params.code || params.error_description) {
            handleNibssCallback(params);
        } else {
            handleError(BAD_REQUEST);
        }
    }, []);

    const handleClientInit = (params) => {
        const { clientKey: client_key, ref: reference, customerId, channel } = params;
        if (!client_key || !reference || !customerId) {
            handleError(BAD_REQUEST)
        } else {
            dispatch(clearClientData());
            dispatch(setClientData({ client_key, reference }));

            NetworkAdapter.initBvnRequest({ reference, customerId, channel })
                .then((result) => {
                    dispatch(setClientData({ redirectUrl: result.data.redirectUrl }));
                    window.location.replace(result.data.authUrl);
                }).catch((error) => {
                    handleError(error);
                });
        }
    }

    const handleNibssCallback = (auth_data) => {
        dispatch(setClientData(auth_data));
        navigate('/callback', { replace: true });
    }

    const handleError = (error) => {
        if (error?.data?.redirectUrl) {
            const redirect_url = `${error.data.redirectUrl}?status=fail&code=${error.code || UNKNOWN_ERROR.code}&error_description=${error.message || UNKNOWN_ERROR.message}`;
            window.location.replace(redirect_url);
        } else {
            dispatch(setClientData({ error_message: error?.message }))
            navigate('/error', { replace: true });
        }
    }

    return (
        <SuspenseFallback />
    );
}

export default ParamsResolver;